import Vue from 'vue'
import VueRouter from 'vue-router'
import SlideShow from '../components/SlideShow.vue'
import ProjectDetail from '../components/ProjectDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: SlideShow
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "about" */ '../components/ProjectList.vue')
  },
  {
    path: '/projects/:id',
    name: 'ProjectDetail',
    // component: () => import(/* webpackChunkName: "about" */ '../components/ProjectDetail.vue')
    component: ProjectDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
