<template>
    <div>
        <div class="rounded-square flex-container">
            <div class="fading-text">
                <div v-for="(text, index) in textList" :key="index" :class="textSize(text)  + (index === 0 ? '' : ' hidden')" :id="'item-' + index">
                    {{text}}
                </div>
                <!-- <div id="text-1" class="big-text">
                    Towards a definition of project art
                </div>
                <div id="text-2" class="medium-text fade-out hidden">
                    Do not abuse your authority as artist of the project.
                </div>
                <div id="text-3" class="normal-text fade-out hidden">
                    Project art often encompasses activities that are normally considered tangential to or in the service of the art-making process – e.g. the phone call, the letter, the research, the conversation, & the failed attempt.
                </div> -->
            </div>
        </div>
        <div class="flex-container button-group">
            <a href="#" class="rounded-square-button" @click="goBack()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#ff7292"><path d="M117.52,98.76906v-61.92047l-116.76078,76.15141l116.76078,76.15141v-61.92047l94.92,61.90281v-152.28516z"></path></g></g></svg>
            </a>
            <a href="#" class="rounded-square-button" v-if="!showIsPlaying" @click="!showIsPlaying ? resumeShow() : doNothing()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#ff7292"><path d="M45.2,23.73v178.52234l151.73781,-89.25234z"></path></g></g></svg>
            </a>
            <a href="#" class="rounded-square-button" v-if="showIsPlaying" @click="stopShow()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ff7292"><path d="M41.28,27.52v116.96h34.4v-116.96zM96.32,27.52v116.96h34.4v-116.96z"></path></g></g></svg>
            </a>
            <a href="#" class="rounded-square-button" @click="goForward()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#ff7292"><path d="M225.24078,113l-116.76078,-76.15141v61.92047l-94.92,-61.92047v152.30281l94.92,-61.92047v61.92047z"></path></g></g></svg>
            </a>
            <a title="Towards a definition of project art...Ethical considerations in project art. 2005-2021, AP 210."  target="_blank" href="https://antinomianpress.org/pdfs/cat_texts/36.%20Towards%20a%20definition%20of%20project%20art...Ethical%20considerations%20in%20project%20art%20catalytic%20text.pdf" class="rounded-square-button" @click="doNothing()">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ff7292"><path d="M71.66667,14.33333v64.5h-28.66667l43,43l43,-43h-28.66667v-64.5zM14.33333,143.33333v14.33333h143.33333v-14.33333z"></path></g></g></svg>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            timerOne:0,
            timerTwo:0,
            timerThree:0,
            resumeTimer:0,
            currentSlide:0,
            showIsPlaying: true,
            textList: [
                'Towards a definition of project art',
                'Project art considers value structures outside of the art historical discourse.',
                'Project art involves the public and the artist in a dialogue that primarily occurs outside of the art world.',
                'Project art often involves collaborations between artists and non-artists.',
                'Project art often requires research into the space in which the project is occurring.',
                'In project art, the community influences the content and structure of the project as the project takes place in the community.',
                'Project art changes in response to particular environments and situations.',
                'Project art usually takes place on the street, on the move, or in your home, and only rarely in exhibition spaces.',
                'Project art is often defined by its duration and interaction with others and is not limited by physical dimensions.',
                'Project art is often ephemeral or transitory.',
                'Project art often encompasses activities that are normally considered tangential to or in the service of the art-making process – e.g. the phone call, the letter, the research, the conversation, & the failed attempt.',
                'Project art does not set out to create an art object to be sold and resold.',
                'Project art uses social structures to achieve relationships that are often unattainable in other art mediums.',
                'Ethical consider-ations in project art',
                'Project art should carefully consider the context in which the project is occurring.',
                'Artists should consider the participants’ interests and expectations.',
                'The artist’s intent and actions in a project should not contradict the known will of the participants.',
                'Artists should provide accurate information for participants to make an informed decision about their role in the project.',
                'Participant and artist alike should be able to end their relationship at any point during the project.',
                'Projects should not harm participants physically or mentally.',
                'Projects should not disenfranchise participants socially or economically.',
                'Participants should be treated with dignity.',
                'Projects should minimize personal risks and hazards to the public and the environment.',
                'Artists should acknowledge the contribution of the participants and express appreciation.',
                'Projects should respect confidentiality and privacy where applicable.',
                'Artists should explain to participants how their material and contributions might be used or made public – e.g. in a publication, archive, exhibition, or website – and obtain consent when possible.',
                'Artists should make the economics of the project transparent.',
                'Artists should be clear with participants as to what will and will not be provided as part of a project.',
                'Artists should explain to participants how their contribution might be viewed and understood.',
                'Artists should be clear with participants and themselves about where authorship lies.',
                'Artists should give participants the option to remain anonymous.',
                'Artists should honor commitments made through a project and not make commitments that cannot be fulfilled.',
                'Artists should be clear with participants about the anticipated duration of the project and how it is expected to end.',
                'Artists should consider any long-term consequences of the project.',
                'Artists should attempt to resolve disputes through dialogue before resorting to legal action.',
                'Artists should be clear about their degree of responsibility and accountability for the project.',
                'Do not abuse your authority as artist of the project.'
            ]
        };
    },
    computed: {
        },
    methods: {
        textSize(text) {
            if(text.length <= 38) {
                return 'big-text';
            } else if (text.length < 100) {
                return 'medium-text';
            } else if (text.length < 150) {
                return 'normal-text';
            } else {
                return 'small-text';
            }
        },
        fadeOut(id) {
            document.querySelector(id).classList.add('fade-out');
            document.querySelector(id).classList.remove('fade-in');
        },
        fadeIn(id) {
            document.querySelector(id).classList.add('fade-in');
            document.querySelector(id).classList.remove('fade-out');
        },
        hide(id) {
            document.querySelector(id).classList.add('hidden');
        },
        show(id) {
            document.querySelector(id).classList.remove('hidden');
        },
        cycle(current, next, last) {
            this.fadeOut(current);
            this.timerOne = setTimeout(() => {
                this.hide(current);
                this.show(next);
                this.timerTwo = setTimeout(() => {
                    this.fadeIn(next);
                    this.timerThree = setTimeout(() => {
                        this.cycle(next, last, current);
                    }, (next === '#text-3') ? 10000: 5000);
                }, 500);
            },1000 );
        },
        cycleList(current, size) {
            let currentId = '#item-' + current;
            this.currentSlide = current;
            this.timerOne = setTimeout(() => {
                this.show(currentId);
                this.fadeIn(currentId);
                if(this.showIsPlaying) {
                    this.timerTwo = setTimeout(() => {
                        this.fadeOut(currentId);
                        this.timerThree = setTimeout(() => {
                            this.hide(currentId);
                            if(current + 1 < size) {
                                this.currentSlide = current + 1;
                                this.cycleList (current + 1, size);
                            } else {
                                this.cycleList (0, size);
                            }
                        }, 500);
                    }, (this.textList[current].length < 75) ? 5000 : 8000);
                }
            }, 1000);
        },
        runShow() {
            this.cycleList(0, this.textList.length);
            // this.cycle('#text-3', '#text-1', '#text-2');
        },
        stopShow() {
            this.showIsPlaying = false;
            if (this.timerOne) {
                clearTimeout(this.timerOne);
                this.timerOne = 0;
            }
            if (this.timerTwo) {
                clearTimeout(this.timerTwo);
                this.timerTwo = 0;
            }
            if (this.timerThree) {
                clearTimeout(this.timerThree);
                this.timerThree = 0;
            }
            if (this.resumeTimer) {
                clearTimeout(this.resumeTimer);
                this.resumeTimer = 0;
            }
        },
        resumeShow() {
            this.stopShow();
            this.fadeOut('#item-' + this.currentSlide);
            this.showIsPlaying = true;
            this.timerOne = setTimeout(()=> {
                this.hide('#item-' + this.currentSlide);
                if(this.currentSlide + 1 < this.textList.length) {
                    this.cycleList(this.currentSlide + 1, this.textList.length);
                } else {
                    this.cycleList(0, this.textList.length);
                }
            }, 1000);
        },
        goBack() {
            this.stopShow();
            this.hide('#item-' + this.currentSlide);
            if(this.currentSlide > 0) {
                this.currentSlide--;
            }
            this.show('#item-' + this.currentSlide);
            this.fadeIn('#item-' + this.currentSlide);
            this.resumeTimer = setTimeout(() => {
                this.resumeShow();
            }, 8000);
        },
        goForward() {
            this.stopShow();
            this.hide('#item-' + this.currentSlide);
            if(this.currentSlide + 1 < this.textList.length) {
                this.currentSlide++;
            }
            this.show('#item-' + this.currentSlide);
            this.fadeIn('#item-' + this.currentSlide);
            this.resumeTimer = setTimeout(() => {
                this.resumeShow();
            }, 8000);
        },
        doNothing(){
            // Does nothing
        }
    },
    mounted() {
        this.runShow();
    },
    beforeDestroy() {
        this.stopShow();
    }
}
</script>

<style scoped>
    .rounded-square {
        border-width: 10px;
        border-style :solid;
        border-color: #ff7292;
        border-radius: 20px;
        padding: 10px;
        height: 80vw;
        width: 80vw;
        max-width: 500px;
        max-height: 500px;
        margin: auto;
        margin-top: 25px;
        font-family: 'Helvetica-Bold', 'Arial Bold' sans-serif;
    }
    .rounded-square-button {
        text-align: center;
        border-style :none;
        padding: 10px;
        height: 10vw;
        width: 10vw;
        max-width: 50px;
        max-height: 50px;
        margin-top: 25px;
        font-family: 'Helvetica-Bold', 'Arial Bold' sans-serif;

    }
    .play-button { content:"\e072"; }
    .button-group {
        max-width: 500px;
        width: 80vw;
        margin: auto;
        justify-content: space-between;
    }
    /* @media screen and (max-width: 800px) {
        .rounded-square {
            margin:initial;
            height: 100vw;
            width: 100vw;
            max-width: unset;
            max-width: unset;
        }
    } */
    .flex-container {
        display: flex;
        align-items: center;
    }
    .fading-text {
        /* height: 50vw; */
        width: 80vw;
        max-width: 500px;
        max-height: 500px;
        /* position: fixed; */
        color:#ff7292;
        font-weight: bold;
        margin: 10px;
    }
    .big-text {
        font-size: 15vw;
    }
    @media screen and (min-width: 630px) {
        .big-text {
            font-size: 6em;
        }
    }
    .medium-text {
        font-size: 9.3vw;
    }
    @media screen and (min-width: 630px) {
        .medium-text {
            font-size: 3.75em;
        }
    }
    .normal-text {
        font-size: 8vw;
    }
    @media screen and (min-width: 630px) {
        .normal-text {
            font-size: 3.25em;
        }
    }
    .small-text {
        font-size: 6vw;
    }
    @media screen and (min-width: 630px) {
        .small-text {
            font-size: 2.5em;
        }
    }
    .hidden {
        display: none;
        /* opacity: 0; */
        /* transition-duration: 1s; */
    }
    .fade-out {
        transition-duration: 1s;
        opacity: 0;
    }
    .fade-in {
        transition-duration: .51s;
        opacity: 1;
    }
</style>