<template>
<div>
        <div class="flex-container">
            <div>
                <router-link v-if="previousItem != 'eof'" class="nav-prev" :to="'./' + previousItem">
                    <svg class="arrows" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#ff7292"><path d="M136.87125,37.43125l-72.32,72.32l-3.1075,3.24875l3.1075,3.24875l72.32,72.32l6.4975,-6.4975l-69.07125,-69.07125l69.07125,-69.07125z"></path></g></g></svg>
                </router-link>
            </div>
            <div>
                <router-link v-if="nextItem != 'eof'" class="nav-next" :to="'./' + nextItem">
                    <svg class="arrows" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 226 226" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,226v-226h226v226z" fill="none"></path><g fill="#ff7292"><path d="M89.12875,37.43125l-6.4975,6.4975l69.07125,69.07125l-69.07125,69.07125l6.4975,6.4975l72.32,-72.32l3.1075,-3.24875l-3.1075,-3.24875z"></path></g></g></svg>
                </router-link>
            </div>
        </div>
        <div class="item-content">
            <div class="item-title">{{ currentItem.title }}</div>
            <div v-html="currentItem.description"></div>
            <div class="item-links">
                <span v-for="(listItem, index) in currentItem.relatedLinks" :key="index">
                    {{ listItem.linkDescription }}<a :href="listItem.url" :target="listItem.target">{{ listItem.linkText }}</a>
                </span>
            </div>
            <div v-for="(image, index) in currentItem.images" class="img-responsive" :key="index">
                <img :src="image.url" :alt="image.altText">
            </div>
        </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            list: []
        }
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        currentItem() {
            for(let i = 0; i < this.sortedList.length; i++){
                if (this.sortedList[i].linkId === this.id) {
                    return this.sortedList[i];
                }
            }
            return { "title": undefined};
        },
        currentIndex() {
            for(let i = 0; i < this.sortedList.length; i++){
                if (this.sortedList[i].linkId === this.id) {
                    return i;
                }
            }
            return false;
        },
        nextItem() {
            if(this.currentIndex + 1 < this.sortedList.length) {
                return this.sortedList[this.currentIndex + 1].linkId;
            } else {
                return 'eof';
            }
        },
        previousItem() {
            if(this.currentIndex > 0) {
                return this.sortedList[this.currentIndex - 1].linkId;
            } else {
                return 'eof';
            }
        },
        sortedList() {
            return _.orderBy(this.list, ['number', 'year', 'month', 'day'], ['desc', 'asc', 'asc', 'asc']);
        }
    },
    created() {
        this.$http.get(`../data/project_list.json?t=${new Date().getTime()}`)
            .then(response => response.json())
            .then(data => this.list = data[Object.keys(data)[0]])
            .then(() => this.resultsClass = '');
    }
}
import _ from 'lodash'
</script>

<style scoped>
    .img-responsive {
        width: 100%;
        text-align: center;
    }
    img {
        width: 100%;
        max-width: 400px;

    }
    @media screen and (max-width: 600px) {
        .nav-next {
            /*  */
            margin-right: 15px;
        }
    }
    .arrows {
        height: 25px;
        width: 25px;;
    }
    @media screen and (max-width: 600px) {
        .arrows {
            height: 37px;
            width: 37px;
        }
    }
    .flex-container {
        /* justify-content: space-between; */
        /* max-width: 630px; */
        /* width: 100%; */
        display: flex;
        float: right;
    }
    @media screen and (max-width: 600px) {
        .flex-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            width: 100%;
            max-width: 630px;
            margin: auto;
            bottom: 0;
            padding-top:10px;
            padding-bottom: 10px;
            /* padding-right: 20px; */
            background-color: white;
        }
    }
    .item-links {
        text-align: right;
        padding-bottom: 40px;
    }
    .item-content {
        padding-bottom: 30px;
    }
    .item-title {
        font-size: large;
        font-weight: bold;
    }
    .nav-next {
        /*  */
        /* margin-right: 15px; */
    }
    .nav-prev {
        /*  */
    }
</style>