<template>
  <div id="app" class="page-container">
    <div id="nav">
      <site-header></site-header>
    </div>
    <!-- <router-view/> -->
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue';
export default {
  data() {
    return {};
  },
  components: {
    SiteHeader
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
  a {
      color: #444F51;
      /* text-decoration: none; */
  }
  a:hover {
      text-decoration: underline;
      color: #252e30;
  }
  .page-container {
    max-width: 630px;
    margin: auto;
    color: #444F51;
    font-family: Helvetica, Arial, sans-serif;
  }
</style>
