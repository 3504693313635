<template>
    <div >
        <div class="flex-container">
            <div class="header-title"><router-link to="/">BenKinmont.com</router-link></div>
            <div class="flex-container">
                <div class="header-nav-item"><router-link to="/projects">projects</router-link></div>
                <div class="header-nav-item"><router-link to="/">home</router-link></div>
            </div>
        </div>
        <hr>
        <router-view/>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
    a {
        color: #444F51;
        text-decoration: none;
    }
    a:hover {
        text-decoration: none;
    }
    .header-title a {
        font-size: 2em;
        font-family: 'Archivo Black', 'Arial Black', Arial, sans-serif;
        /* font-weight: bold; */
        color: #ff7292;
    }
    .header-nav-item {
        font-size: 1em;
        font-family: Helvetica, Arial, sans-serif;
        padding-left: 20px;
        /* margin-top: 5px; */
    }
    hr {
        border-style: solid;
        color: #dcdcdc;
    }
    .flex-container {
        display:flex;
        flex-flow: wrap;
        justify-content: space-between;
        align-items: baseline;
    }

</style>